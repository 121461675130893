import React, { Component } from "react";
import Leave_Requests from "../../components/leave-requests/leave-requests";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import gear from "../../images/gear.svg";

class Leave_Requests_Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      leaveReq: [],
    };
  }

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests?status=Pending`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        this.setState({
          leaveReq: responseJson.data,
          isLoaded: true,
        });
      });

    console.log("done");
  }

  refreshPage = () => {
    this.componentDidMount();
  };

  render() {
    return (
      <div className="mb-4">
        {this.state.leaveReq == "" ? (
          this.state.isLoaded == true ? (
            <div className="nodata">
              <img src={nodata} alt="" />
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loader">
              <TailSpin color={"#34b0dd"} />
            </div>
          )
        ) : (
          <div className="wrapper-array">
            {this.state.leaveReq.map((leaveReq) => (
              <Leave_Requests
                key={leaveReq.id}
                refreshPage={this.refreshPage}
                id={leaveReq.id}
                days={leaveReq.days}
                startDate={leaveReq.startDate}
                endDate={leaveReq.endDate}
                username={leaveReq.userId.username}
                reason={leaveReq.reason}
                type={leaveReq.type}
                document={leaveReq.document}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Leave_Requests_Wrapper;
