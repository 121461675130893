import React, { Component, useState } from "react";
import "./delete-users-modal.css";
import cross from "../../images/cross.svg";
import { toast } from "react-hot-toast";

const Delete_Users_Modal = (props) => {
  if (!props.show) {
    return null;
  }

  let error_message;

  // const ban_user = () => {
  //   if (!props.disabled) {
  //     const loader = toast.loading("Loading");
  //     props.setDisabled(true);
  //     const promise = fetch(
  //       `${process.env.REACT_APP_BASEURL}/api/v1/users/${props.id}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         if (responseJson.success) {
  //           toast.success(`User Status Updated`, {
  //             id: loader,
  //           });
  //         } else
  //           toast.error(`${responseJson.message}`, {
  //             id: loader,
  //           });
  //         console.log(responseJson);
  //         props.setDisabled(false);
  //         props.onClose();
  //         props.refreshPage();
  //       })
  //       .catch((err) => {
  //         toast.error(`An error has occurred`);
  //       });
  //   }
  // };

  return (
    <div className="custom-modal">
      <div className="del-users-modal-content">
        <div className="del-users-modal-text">
          <h2>
            Are you sure you want to{" "}
            {props.status == "Active" ? "deactivate" : "activate"} {props.name}?
          </h2>
          <div className="mt-4 del-modal-btns">
            <a onClick={props.method} className="red-hover w-50 delete-btn">
              {props.status == "Active" ? "Deactivate" : "Activate"}
            </a>
            <a onClick={props.onClose} className="black-hover w-50 accept-btn">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete_Users_Modal;
