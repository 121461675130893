import React, { Component } from "react";
import NavBar from "../components/navbar/navbar";
import "./food.css";
import Dishes from "../components/dishes/dishes";
import Requests from "../components/requests/requests";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Daily_Voting from "../components/daily-voting/daily-voting";
import Search_Bar from "../components/search-bar/search-bar";
import Add_Dish from "../components/add-dish/add-dish";
import Edit_Dish from "../components/edit-dish/edit-dish";
class Food extends Component {
  state = {
    foodInfo: [
      {
        image: "",
        name: "Chicken Karahi",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        id: "1",
      },
      {
        image: "",
        name: "Biryani",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        id: "2",
      },
      {
        image: "",
        name: "BBQ Mandi",
        desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        id: "3",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <div>
          <ul className="list-food">
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                exact
                to={"/digitaez/food"}
                className="shadow"
                href=""
              >
                <h3>Dishes</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/food/add-dish"}
                className="shadow"
                href=""
              >
                <h3>Add Dish</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/food/requests"}
                className="shadow"
                href=""
              >
                <h3>Requests</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/food/daily-voting"}
                className="shadow"
                href=""
              >
                <h3>Voting</h3>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="user-array-food mt-4"></div>
        <Route exact path="/digitaez/food" component={Search_Bar} />
        <div className="wrapper-array">
          <Route
            exact
            path="/digitaez/food"
            component={() =>
              this.state.foodInfo.map((foodInfo) => (
                <Dishes
                  key={foodInfo.id}
                  name={foodInfo.name}
                  profession={foodInfo.profession}
                  desc={foodInfo.desc}
                />
              ))
            }
          />
        </div>
        <Route path="/digitaez/food/requests" component={Requests} />
        <Route path="/digitaez/food/daily-voting" component={Daily_Voting} />
        <Route path="/digitaez/food/add-dish" component={Add_Dish} />
        <Route path="/digitaez/food/edit-dish" component={Edit_Dish} />
      </React.Fragment>
    );
  }
}

export default Food;
