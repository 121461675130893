import React, { Component } from "react";
import burger from "../../images/burger.png";
import { Link } from "react-router-dom";
import "./redeem-shop.css";
class Redeem_Shop extends React.Component {
  render() {
    return (
      <div className="redeem-wrapper shadow">
        <div className="redeem-img-wrapper">
          <img src={burger} alt="" />
        </div>
        <div>
          <h2>Item Name</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
          </p>
          <div className="redeem-buttons">
            <a className="redeem-redbtn shadow-sm" href="">
              Remove
            </a>
            <Link
              to={"/digicoins/edititem"}
              className="ms-3 shadow-sm redeem-bluebtn"
              href=""
            >
              Edit
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Redeem_Shop;
