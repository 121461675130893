import React, { Component } from "react";
import Notifications from "../components/notifications/notifications";
import "./notification-panel.css";
class Notification_Panel extends React.Component {
  render() {
    return (
      <div className="notification-panel-wrapper">
        <h2>Notifications</h2>
        <div className="notification-bar">
          <Notifications />
          <Notifications />
          <Notifications />
          <Notifications />
        </div>
        <a className=" shadow" href="">
          <h3>Clear Notifications</h3>
        </a>
      </div>
    );
  }
}

export default Notification_Panel;
