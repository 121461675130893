import React, { Component } from "react";
class Month_Bar extends Component {
  state = {};
  render() {
    return (
      <div className=" mb-4">
        <div className="form-format-date">
          <div>
            <h1 className="datebar-month">2021-2020</h1>
          </div>
          <div className="datebar-filters">
            <div>
              <h3 className="ms-5">Filters</h3>
            </div>

            <div class=" ms-4 dropdown">
              <button class="shadow dropbtn">
                <h3>This Month</h3>
              </button>
              <div class="dropdown-content">
                <a href="#">January</a>
                <a href="#">Februaru</a>
                <a href="#">March</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Month_Bar;
