import React, { Component } from "react";
import "./all-users-modal.css";
import profile from "../../images/profile.jpg";

const All_Users_Modal = (props) => {
  if (!props.show) {
    return null;
  }
  const convertToInt = (num) => {
    const updatedNum = parseInt(num);
    return updatedNum;
  };
  return (
    <div id="modal-root" className="custom-modal" onClick={props.onClose}>
      <div className="modal-content">
        <img
          className="profile-img user-modal-img mb-3"
          src={
            props.photo === "no-photo.jpg"
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/profile/${props.photo}`
          }
          alt=""
        />
        <p>
          <strong>Full Name:</strong> {props.username}
        </p>
        <p>
          <strong>Email:</strong> {props.email}
        </p>
        <p>
          <strong>Designation:</strong> {props.designation}
        </p>
        <p>
          <strong>Role:</strong> {props.role}
        </p>
        <p>
          <strong>Joining Date:</strong> {props.joiningDate.substr(0, 10)}
        </p>
        <p>
          <strong>Status:</strong> {props.status}
        </p>
        <p>
          <strong>Hours Worked:</strong> {convertToInt(props.hoursWorked)}
        </p>
        <p>
          <strong>Leaves:</strong> {props.leaves}
        </p>
      </div>
    </div>
  );
};

export default All_Users_Modal;
