import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./add-itembar.css";
class Add_Itembar extends React.Component {
  render() {
    return (
      <div className="add-itembar-wrapper mb-4">
        <h1>Redeem Items</h1>
        <Link to={"/digicoins/add-item"} className="shadow-sm" href="">
          Add Item
        </Link>
      </div>
    );
  }
}

export default Add_Itembar;
