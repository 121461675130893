import "./profile-view.css";
import profile from "../../images/profile.jpg";

const Profile_View = (props) => {
  return (
    <div className="view-profile-form shadow mt-3">
      <img
        src={
          props.current_user.photo === "no-photo.jpg"
            ? profile
            : `${process.env.REACT_APP_BASEURL}/uploads/profile/${props.current_user.photo}`
        }
        alt=""
      />
      <form className="mt-5">
        <div>
          <div className="vendor-form-arrange">
            <div>
              <label className="mt-4" for="fullname">
                Full Name
              </label>
              <br></br>
              <input
                className="form-control  form-rounded"
                required
                type="text"
                disabled="disabled"
                value={props.current_user.username}
              ></input>

              {/* <label className="mt-4" for="password">
                    Password
                  </label>
                  <br></br>
                  <input
                    className="form-control  form-rounded"
                    required
                    type="password"
                    disabled="disabled"
                    id="password"
                    name="password"
                    {...register("password")}
                  ></input> */}

              <label className="mt-4" for="cnic">
                Designation
              </label>
              <br></br>
              <input
                className="form-control  form-rounded"
                required
                type="string"
                value={props.current_user.designation}
                disabled="disabled"
              ></input>
            </div>
            <div>
              <label className="mt-4" for="email">
                Email
              </label>
              <br></br>
              <input
                className="form-control  form-rounded"
                required
                type="email"
                value={props.current_user.email}
                disabled="disabled"
              ></input>
              {/* <label className="mt-4" for="password_confirmation">
                    Confirm Password
                  </label>
                  <br></br>
                  <input
                    className="form-control  form-rounded"
                    required
                    type="password"
                    disabled="disabled"
                    name="password_confirmation"
                    {...register("password_confirmation")}
                  ></input> */}

              <label className="mt-4" for="phone">
                Role
              </label>
              <br></br>
              <input
                className="form-control  form-rounded"
                required
                type="string"
                disabled="disabled"
                value={props.current_user.role}
              ></input>
            </div>
          </div>
        </div>
      </form>
      {/* <div
            className={` 
              ${errors.email?.message ? "error-display" : "hide"}
              ${errors.password?.message ? "error-display" : "hide"}
              ${errors.password_confirmation?.message ? "error-display" : "hide"}
              ${errors.phone_no?.message ? "error-display" : "hide"}
              ${errors.cnic?.message ? "error-display" : "hide"}
              `}
          >
            <h3 className={errors.email?.message ? "" : "hide"}>
              {errors.email?.message}
            </h3>
    
            <h3 className={errors.password?.message ? "" : "hide"}>
              {" "}
              {errors.password?.message}
            </h3>
    
            <h3 className={errors.cnic?.message ? "" : "hide"}>
              {" "}
              {errors.cnic?.message}
            </h3>
    
            <h3 className={errors.phone_no?.message ? "" : "hide"}>
              {errors.phone_no?.message}
            </h3>
            <h3 className={errors.password_confirmation?.message ? "" : "hide"}>
              {errors.password_confirmation?.message}
            </h3>
          </div> */}
    </div>
  );
};

export default Profile_View;
