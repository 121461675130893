import React, { Component } from "react";
import profile from "../../images/profile-edit.jpg";
import "./allot-dgc.css";
import coin from "../../images/coin.svg";
class Allot_Dgc extends React.Component {
  render() {
    return (
      <div className="bigwrapper shadow">
        <div className="pt-5 second-wrapper">
          <img className="shadow" src={profile} alt="" />
          <form>
            <div className="seperator">
              <div>
                <label for="fname">Item Name</label>
                <br></br>
                <input
                  className="form-control  mt-2 form-rounded-editdish"
                  type="text"
                  id="dname"
                  name="dname"
                  size="10"
                ></input>
                <br></br>
              </div>
            </div>
            <div className="current-dgc">
              <label>Current Digicoins:</label>
              <a className="ms-2" href="">
                <img src={coin} alt="" />
                2000 DGC
              </a>
            </div>
            <div className="mt-4">
              <div className="redeem-form">
                <label for="dgc-cost">Digicoins Cost:</label>

                <input
                  className="form-horizontal form-control allot-additem  form-rounded-additem"
                  type="number"
                  id="dgc-cost"
                  name="dgc-cost"
                  size="5"
                  min="0"
                ></input>
              </div>
            </div>
            <div className="submit-form pb-4">
              <input
                className=" me-3 submit-btn editdish-btn-discard"
                type="reset"
                value="Discard"
              ></input>
              <input
                className=" submit-btn editdish-btn-save"
                type="submit"
                value="Add Item"
              ></input>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Allot_Dgc;
