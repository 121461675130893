import React, { Component } from "react";
import "./digicoins.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Employee_Day from "../components/employee-day/employee-day";
import Date_Bar from "../components/date-bar/date-bar";
import Month_Bar from "../components/month-bar/month-bar";
import Employee_Month from "../components/employee-month/employee-month";
import User_Digicoins from "../components/user-digicoins/user-digicoins";
import Redeem_Shop from "../components/redeem-shop/redeem-shop";
import Redeem_Requests from "../components/redeem-requests/redeem-requests";
import Allotment from "../components/allotment/allotment";
import Add_Itembar from "../components/add-itembar/add-itembar";
import Redeem_Additem from "../components/redeem-additem/redeem-additem";
import Redeem_Edititem from "../components/redeem-edititem/redeem-edititem";
import Allot_Dgc from "../components/allot-dgc/allot-dgc";
class Digicoins extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div>
          <ul className="list-dgc">
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                exact
                to={"/digitaez/digicoins"}
                className="shadow"
              >
                <h3>Employee of the Day</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/digicoins/employee-month"}
                className="shadow"
                href=""
              >
                <h3>Employee of the Month</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/digicoins/allotment"}
                className="shadow"
                href=""
              >
                <h3>Allotment</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/digicoins/redeem-shop"}
                className="shadow"
                href=""
              >
                <h3>Redeem Shop</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/digicoins/user-digicoins"}
                className="shadow"
                href=""
              >
                <h3>User Digicoins</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/digicoins/redeem-requests"}
                className="shadow"
                href=""
              >
                <h3>Redeem Requests</h3>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="user-array-dgc mt-4"></div>
        <Route exact path="/digitaez/digicoins" component={Date_Bar} />
        <Route exact path="/digitaez/digicoins" component={Employee_Day} />
        <Route
          path="/digitaez/digicoins/employee-month"
          component={Month_Bar}
        />
        <Route
          path="/digitaez/digicoins/employee-month"
          component={Employee_Month}
        />
        <Route
          path="/digitaez/digicoins/user-digicoins"
          component={User_Digicoins}
        />
        <Route path="/digitaez/digicoins/redeem-shop" component={Add_Itembar} />
        <Route path="/digitaez/digicoins/redeem-shop" component={Redeem_Shop} />
        <Route path="/digitaez/digicoins/add-item" component={Redeem_Additem} />
        <Route
          path="/digitaez/digicoins/edititem"
          component={Redeem_Edititem}
        />
        <Route path="/digitaez/digicoins/allot-dgc" component={Allot_Dgc} />

        <Route
          path="/digitaez/digicoins/redeem-requests"
          component={Redeem_Requests}
        />
        <Route path="/digitaez/digicoins/allotment" component={Allotment} />
      </React.Fragment>
    );
  }
}

export default Digicoins;
