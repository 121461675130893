import "./working-hours.css";
import profile from "../../images/profile.jpg";
const Working_Hours = (props) => {
  const convertToInt = (num) => {
    const updatedNum = parseInt(num);
    return updatedNum;
  };

  return (
    <div className="attend-wrapper shadow">
      <div className="attend-container">
        <img
          src={
            props.photo === "no-photo.jpg"
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/profile/${props.photo}`
          }
          alt=""
        />
        <div className="attend-text">
          <h2>{props.username}</h2>
          <h3>{props.designation}</h3>
          <p>Worked {convertToInt(props.hoursWorked)} hours this month</p>
        </div>
      </div>
    </div>
  );
};

export default Working_Hours;
