import React, { Component } from "react";
import profile from "../../images/profile-edit.jpg";
import "./redeem-additem.css";
class Redeem_Additem extends React.Component {
  render() {
    return (
      <div className="bigwrapper shadow">
        <div className="pt-5 second-wrapper">
          <img className="shadow" src={profile} alt="" />
          <form>
            <div className="seperator">
              <div>
                <label for="fname">Item Name</label>
                <br></br>
                <input
                  className="form-control  mt-2 form-rounded-editdish"
                  type="text"
                  id="dname"
                  name="dname"
                  size="10"
                ></input>
                <br></br>
                <label for="lname">Description</label>
                <br></br>
                <textarea
                  className="form-control mt-2 form-rounded-editdish"
                  type="text"
                  id="desc"
                  name="desc"
                  size="30"
                  rows="3"
                ></textarea>
                <br></br>
              </div>
            </div>
            <div className="mt-2">
              <div className="redeem-form">
                <label for="dgc-cost">Digicoins Cost:</label>

                <input
                  className="form-horizontal form-control ms-2  form-rounded-additem"
                  type="number"
                  id="dgc-cost"
                  name="dgc-cost"
                  size="5"
                ></input>
              </div>
            </div>
            <div className="submit-form pb-4">
              <input
                className=" me-3 submit-btn editdish-btn-discard"
                type="reset"
                value="Discard"
              ></input>
              <input
                className=" submit-btn editdish-btn-save"
                type="submit"
                value="Add Item"
              ></input>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Redeem_Additem;
