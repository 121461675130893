import React, { Component } from "react";
import "./date-bar.css";
class Date_Bar extends Component {
  state = {};
  render() {
    return (
      <div className=" mb-4">
        <div className="form-format-date">
          <div>
            <h1 className="datebar-month">August</h1>
          </div>
          <div className="datebar-filters">
            <div>
              <h3 className="ms-5">Filters</h3>
            </div>

            <div class=" ms-4 dropdown">
              <button class="shadow dropbtn">
                <h3>All Users</h3>
              </button>
              <div class="dropdown-content">
                <a href="#">Link 1</a>
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Date_Bar;
