import { useState } from "react";

import All_Users from "../../components/all-users/all-users";
import Search_Bar from "../../components/search-bar/search-bar";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import View_Attendance from "../../components/view-attendance/view-attendance";
import gear from "../../images/gear.svg";

const Absent_Wrapper = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterTerm, setFilterTerm] = useState(true);

  const filterSelecter = (e) => {
    console.log(filterTerm);
  };

  return (
    <div>
      {/* <div className="form-format mt-3 mb-4">
        <Search_Bar
          handleChange={(e) => {
            setSearchTerm(e.target.value);
            console.log(searchTerm);
          }}
        />
        <h3 className="ms-5">Filters</h3>
        <div class=" ms-4 dropdown">
          <select
            name="role"
            className="form-control-lg form-filter"
            required
            id="role"
            onChange={(e) => {
              if (e.target.value === "true") setFilterTerm(true);
              else setFilterTerm(false);
            }}
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
      </div> */}

      {props.absent == "" ? (
        props.isLoaded == true ? (
          <div className="nodata">
            <img src={nodata} alt="" />
            <p>No Data Found</p>
          </div>
        ) : (
          <div className="loader">
            <TailSpin color={"#34b0dd"} />
          </div>
        )
      ) : (
        <div className="wrapper-array">
          {props.absent.map((absent) => (
            <View_Attendance
              username={absent.username}
              designation={absent.designation}
              status={"Absent"}
              photo={absent.photo}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Absent_Wrapper;
