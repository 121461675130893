import React, { Component, useState } from "react";
import profile from "../../images/profile-edit.jpg";
import eye from "../../images/eye.svg";
import eye_slash from "../../images/eye-slash.svg";
import randompas from "../../images/randompas.svg";
import { userSchema } from "../../Validation/add-user-valid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import curve from "../../images/curve.svg";
import "./add-users.css";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Errors_Display from "./../errors-display/errors-display";
import Display_Supervisor from "../display-supervisor/display-supervisor";

const Add_Users = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const [image_file, setImageFile] = useState();
  const [form_error, setForm_error] = useState();
  const [role_check, setRole_check] = useState();
  const [disabled, setDisabled] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [randomPass, setRandomPass] = useState();
  // Math.random().toString(36).slice(-8)

  const splitError = () => {
    if (form_error) {
      const array = form_error.split(",");
      console.log(array);
      return array.map((data, i) => (
        <Errors_Display array={array[i]} index={i} />
      ));
    }
  };

  const generate_random = () => {
    var randomstring = Math.random().toString(36).slice(-8);
    setValue("password", randomstring);
    setShowPass(true);
    // setRandomPass(randomstring);
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const resetForm = () => {
    document.getElementById("addusers").reset();
  };

  //submitting API
  const HandleForm = (data) => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);
      //resetting errors array
      setForm_error("");
      data["file"] = image_file;

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      fetch(`${process.env.REACT_APP_BASEURL}/api/v1/users`, {
        method: "POST",

        headers: {
          // "Content-Type": "application/json",
          // "Content-Length": data.toString().length + "",
          // Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setDisabled(false);
          if (responseJson.success) {
            toast.success(`User added successfully`, {
              id: loader,
            });

            resetForm();
          } else {
            setForm_error(responseJson.error);
            toast.error(`${responseJson.error}`, {
              id: loader,
            });
          }
        })
        .catch((err) => {
          toast.success(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  return (
    <div className="bigwrapper shadow">
      <div className="pt-5 second-wrapper">
        <form
          enctype="multipart/form-data"
          id="addusers"
          onSubmit={handleSubmit(HandleForm)}
        >
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              console.log(e.target.files[0]);
              setImageFile(e.target.files[0]);
            }}
            className="form-control file-upload"
            name="photo"
          ></input>
          {/* <input
            type="file"
            id="picture"
            name="picture"
            // {...register("picture")}
          ></input> */}

          <div className="seperator">
            <div>
              <label for="username">
                Full Name <span className="red">*</span>
              </label>
              <br></br>
              <input
                className="form-control  mt-2 form-rounded"
                required
                type="text"
                value={props.data}
                id="username"
                name="username"
                size="10"
                {...register("username")}
              ></input>
              <br></br>
              <label for="lname">
                Email <span className="red">*</span>
              </label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                required
                type="text"
                id="email"
                name="email"
                size="30"
                {...register("email")}
              ></input>
              <br></br>
              <label for="designation">
                Designation <span className="red">*</span>
              </label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                required
                type="text"
                id="designation"
                name="designation"
                size="30"
                {...register("designation")}
              ></input>
              <br></br>
              <label for="supervisedBy">Supervised By</label>
              <br></br>
              <select
                name="supervisedBy"
                className="form-control mt-2 form-rounded"
                disabled={
                  role_check === "employee" || "supervisor" ? false : true
                }
                id="supervisedBy"
                {...register("supervisedBy")}
              >
                <option value="">None</option>
                {role_check == "employee" || "supervisor"
                  ? props.supervisorInfo.map((supervisor) => (
                      <Display_Supervisor
                        name={supervisor.username}
                        _id={supervisor._id}
                      />
                    ))
                  : null}
              </select>
              <br></br>
            </div>
            <div>
              <label for="JoiningDate">
                Joining Date <span className="red">*</span>
              </label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                required
                type="date"
                id="JoiningDate"
                name="JoiningDate"
                size="30"
                {...register("JoiningDate")}
              ></input>
              <br></br>{" "}
              <label for="pw">
                Password <span className="red">*</span>
              </label>
              <br></br>
              <div className="eye-container">
                <img
                  className="randompas-add-users"
                  src={randompas}
                  onClick={() => generate_random()}
                  alt=""
                />
                <img
                  className="eye-add-users"
                  src={showPass ? eye_slash : eye}
                  onClick={() => {
                    setShowPass((prevCheck) => !prevCheck);
                  }}
                  alt=""
                />
                <input
                  className="form-control password-field mt-2 form-rounded"
                  required
                  autocomplete="new-password"
                  type={showPass ? "text" : "password"}
                  value={randomPass}
                  onChange={(e) => setRandomPass(e.target.value)}
                  // onInput={(e) => setRandomPass(e.target.value)}
                  id="password"
                  name="password"
                  size="20"
                  {...register("password")}
                />
              </div>
              <br></br>
              <label for="role">
                Role <span className="red">*</span>
              </label>
              <br></br>
              <select
                name="role"
                onClick={(e) => {
                  setRole_check(e.target.value);
                  console.log(role_check);
                }}
                className="form-control mt-2 form-rounded"
                required
                id="role"
                {...register("role")}
              >
                {" "}
                <option value="employee">Employee</option>
                <option value="admin">Admin</option>
                <option value="supervisor">Supervisor</option>
              </select>
              <br></br>
              <label for="leaves">Leaves</label>
              <input
                name="leaves"
                className="form-control mt-2 form-rounded"
                min="0"
                type="number"
                id="leaves"
                {...register("leaves")}
              ></input>
              <br></br>
            </div>
          </div>
          <div className="submit-form pb-4">
            <input
              className=" me-3 shadow submit-btn submit-btngrey"
              type="reset"
              value="Discard"
            ></input>
            <input
              className=" submit-btn shadow submit-btnblue "
              type="submit"
              value="Submit"
            ></input>
          </div>
        </form>
        <div className="">
          <h3 className={errors.email?.message ? "red" : "hide"}>
            {errors.email?.message}
          </h3>

          <h3 className={errors.password?.message ? "red" : "hide"}>
            {" "}
            {errors.password?.message}
          </h3>

          <h3 className={errors.cnic?.message ? "red" : "hide"}>
            {" "}
            {errors.cnic?.message}
          </h3>

          <h3 className={errors.phone_no?.message ? "red" : "hide"}>
            {errors.phone_no?.message}
          </h3>
          <h3
            className={errors.password_confirmation?.message ? "red" : "hide"}
          >
            {errors.password_confirmation?.message}
          </h3>
          {form_error ? splitError(form_error) : null}
        </div>
      </div>
    </div>
  );
};

export default Add_Users;
