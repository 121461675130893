import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./dashboard.css";
import Linegraph from "../components/charts/linegraph";
import Donutgraph from "../components/charts/donutgraph";
import Notification_Panel from "./notification-panel";
import Donutgraph_Present from "../components/charts/donutgraph-present";
import Donutgraph_Absent from "../components/charts/donutgraph-absent";
import Dash_Leavereq from "../components/dashboard-leavereq/dashboard-leavereq";

class Dashboard extends Component {
  state = {
    attendance: [11, 4, 2, 12, 7, 14, 16],
    employees_atten: [6, 18],
  };
  componentDidMount() {
    // Simple GET request using fetch

    fetch(`${process.env.REACT_APP_BASEURL}/api/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
  }

  render() {
    return (
      <div className="dashboard-wrapper">
        <div>
          <h1>Daily Attendance</h1>
          <Linegraph attendance={this.state.attendance} />
        </div>
        <div className="dashboard-employee me-2">
          <h1>Employees</h1>
          <div className="dashboard-chart-control">
            <Donutgraph />
            <div className="dashboard-chart-control2">
              <Donutgraph_Absent employees_atten={this.state.employees_atten} />
              <Donutgraph_Present
                employees_atten={this.state.employees_atten}
              />
            </div>
          </div>
        </div>

        <div className="dash-panel">
          <Notification_Panel />
        </div>
        <div className="dashboard-leavereq">
          <h1>Leave Requests</h1>
          <div className="dash-leave-array">
            <Dash_Leavereq />
            <Dash_Leavereq />
            <Dash_Leavereq />
            <Dash_Leavereq />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
