import React, { Component } from "react";
import profile from "../../images/profile.jpg";
import { Link } from "react-router-dom";
import coin from "../../images/coin.svg";
import "./user-digicoins.css";
class User_Digicoins extends Component {
  state = {};
  render() {
    return (
      <div className="dgc-wrapper shadow">
        <img src={profile} alt="" />
        <div>
          <h2>Shemoel Shakil</h2>
          <p>Emmployee of the Day on DD/MM/YYYY</p>
          <div className="dgc-buttons">
            <a className="dgc-coin" href="">
              <img src={coin} /> 2000 DGC
            </a>
            <Link
              to={"/digicoins/allot-dgc"}
              className="dgc-allot shadow-sm"
              href=""
            >
              Allot DGC
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default User_Digicoins;
