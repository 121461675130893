import React, { Component } from "react";
import "./donutgraph.css";
import { Doughnut, Chart } from "react-chartjs-2";
const Donutgraph_Present = (props) => {
  return (
    <div>
      <div className="donut-3">
        <Doughnut
          data={{
            labels: ["Present", "Absent"],
            datasets: [
              {
                label: "People Present",
                data: props.employees_atten,
                backgroundColor: ["rgb(11, 222, 95)", "rgb(255, 74, 74,1)"],
                borderColor: "rgb(0, 173, 239)",
                fill: false,
                borderWidth: 0,
                weight: 1000,
              },
            ],
          }}
          height={-200}
          width={-200}
          options={{
            cutout: 40,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
        <div className="donut-2">
          <h4>Present</h4>

          <h2>{props.employees_atten[0]}</h2>
        </div>
        <canvas id="chart-area" width="100" height="100" />
      </div>
    </div>
  );
};

export default Donutgraph_Present;
