import React, { Component, useState } from "react";
import cross from "../../images/close.svg";
import tick from "../../images/tick.svg";
import "./leave-requests.css";
import profile from "../../images/profile2.jpg";
import { toast } from "react-hot-toast";
import LeaveReq_Modal from "../modals/leaveReq-modal";

const Leave_Requests = (props) => {
  const [show, setShow] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const closePopup = () => {
    setTimeout(() => {
      setShow(false);
    }, 10);
  };

  const accept_request = () => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);

      const data = {
        status: "Approved",
      };

      fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests/${props.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setDisabled(false);
          if (responseJson.success === true) {
            toast.success(`Leave request accepted`, {
              id: loader,
            });
            props.refreshPage();
          } else
            toast.error(`An error has occurred`, {
              id: loader,
            });
        })
        .catch((err) => {
          toast.error(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  const reject_request = () => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);

      const data = {
        status: "Rejected",
      };

      fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/leaveRequests/${props.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.success === true) {
            toast.success(`Leave request rejected`, {
              id: loader,
            });
            props.refreshPage();
          } else
            toast.error(`An error has occurred`, {
              id: loader,
            });
        })
        .catch((err) => {
          toast.error(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  return (
    <div className="leave-wrapper shadow">
      <LeaveReq_Modal
        key={props.id}
        username={props.username}
        refreshPage={props.refreshPage}
        days={props.days}
        startDate={props.startDate.substr(0, 10)}
        endDate={props.endDate.substr(0, 10)}
        reason={props.reason}
        type={props.type}
        document={props.document}
        onClose={closePopup}
        show={show}
      />
      <div className="leave-requests">
        <div>
          <img className="prof shadow-sm" src={profile} alt="" />
        </div>
        <div className="leave-desc" onClick={() => setShow(true)}>
          <h2>{props.username}</h2>
          <p className="mt-3 leave-reason">Reason: {props.reason}</p>
          <p>
            Duration: {props.startDate.substr(0, 10)} to{" "}
            {props.endDate.substr(0, 10)}
          </p>
        </div>
        <div className="check-boxes">
          <div onClick={reject_request} className="cross  red-hover">
            <img src={cross} alt="" />
          </div>

          <div onClick={accept_request} className="tick  green-hover">
            <img src={tick} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave_Requests;
