import React, { Component } from "react";
import gear from "../../images/gear.svg";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import View_Attendance from "./../../components/view-attendance/view-attendance";
import Working_Hours from "./../../components/working-hours/working-hours";
class Working_Hours_Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: [],
      isLoaded: false,
      searchTerm: "",
    };
  }

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/v1/users?select=hoursWorked,username,officeId,role,designation,photo`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        this.setState({
          isLoaded: true,
          hours: responseJson.data,
        });
      });

    console.log("done");
  }

  refreshPage = () => {
    this.componentDidMount();
  };

  handleChange = (e) => {
    this.setState({ searchField: e.target.value });
  };
  render() {
    return (
      <div>
        {" "}
        {this.state.hours == "" ? (
          this.state.isLoaded == true ? (
            <div className="nodata">
              <img src={nodata} alt="" />
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loader">
              <TailSpin color={"#34b0dd"} />
            </div>
          )
        ) : (
          <div className="wrapper-array">
            {this.state.hours.map((hours) => (
              <Working_Hours
                username={hours.username}
                designation={hours.designation}
                hoursWorked={hours.hoursWorked}
                photo={hours.photo}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Working_Hours_Wrapper;
