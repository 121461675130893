import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  NavLink,
} from "react-router-dom";
import "./navbar.css";
import RouteRequiresLogin from "../auth/authLink";
import arrow from "../../images/proceed.svg";
import logo from "../../images/Logo.svg";
import dashboard from "../../images/Dashboard Gradient.svg";
import user from "../../images/User Gradient.svg";
import clock from "../../images/Clock Gradient.svg";
import dish from "../../images/Food Gradient.svg";
import leavereq from "../../images/leavereq.svg";
import dgc from "../../images/DGC Gradient.svg";
import profile from "../../images/profile.jpg";
import Users from "../../containers/users";
import Add_Users from "../add-users/add-users";
import Forget_Password from "../forget-password/forget-password";
import Dashboard from "../../containers/dashboard";
import Office_Timings from "../../containers/office-timings";
import Food from "../../containers/food";
import Digicoins from "../../containers/digicoins";
import All_Users from "../all-users/all-users";
import Attendance from "./../../containers/attendance";
import Profile_View from "./../profile-view/profile-view";
import offices from "../../images/offices.svg";
import SupervisorRender from "../conditional-render/supervisor-render";
import AdminRender from "../conditional-render/admin-render";
import SupervisorRoute from "../auth/supervisor-route";
import SuperadminRoute from "../auth/superadmin-route";
import Leave_Requests_Wrapper from "../../wrappers/Users/Leave_Reqeusts_Wrapper";
import Sign_Out_Modal from "../modals/sign-out-modal";
import Offices from "../../containers/superadmin/offices";
import SuperadminRender from "../conditional-render/superadmin-render";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_user: [],
      isLoaded: false,
      showDel: false,
    };
  }
  componentDidMount() {
    // Get current user info

    fetch(`${process.env.REACT_APP_BASEURL}/api/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        this.setState({
          current_user: responseJson.data,
          isLoaded: true,
        });
      });
  }

  ClearToken = () => {
    localStorage.clear();
    console.log(localStorage.getItem("token"));
  };

  showPopup = () => {
    this.setState({ showDel: true });
  };

  closePopupDel = () => {
    setTimeout(() => {
      this.setState({ showDel: false });
    }, 10);
  };

  render() {
    return (
      <div>
        <Sign_Out_Modal
          show={this.state.showDel}
          onClose={this.closePopupDel}
        />
        <div className="navigation  shadow">
          <ul>
            <li>
              <span className="title ">
                <img className="logo" src={logo} alt="" />
              </span>
            </li>
            <AdminRender>
              {/* <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/dashboard"}
                >
                  <span className="icon">
                    <img src={dashboard} alt="" />
                  </span>
                  <span className="title">
                    <h3 className="font-face-gilroy">Dashboard</h3>
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/users"}
                >
                  <span className="icon">
                    <img src={user} alt="" />
                  </span>
                  <span className="title">
                    <h3>User</h3>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/attendance"}
                >
                  <span className="icon">
                    <img src={user} alt="" />
                  </span>
                  <span className="title">
                    <h3>Attendance</h3>
                  </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/office-timings"}
                >
                  <span className="icon">
                    <img src={clock} alt="" />
                  </span>
                  <span className="title">
                    <h3>Office Timings</h3>
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/food"}
                  href=""
                >
                  <span className="icon">
                    <img src={dish} />
                  </span>
                  <span className="title">
                    <h3>Food</h3>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/digitaez/digicoins"}
                  href=""
                >
                  <span className="icon">
                    <img src={dgc} alt="" />
                  </span>
                  <span className="title">
                    <h3>Digicoins</h3>
                  </span>
                </NavLink>
              </li> */}
            </AdminRender>
            <SupervisorRender>
              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/supervisor/leave-requests"}
                  href=""
                >
                  <span className="icon">
                    <img src={leavereq} alt="" />
                  </span>
                  <span className="title">
                    <h3>Leave Requests</h3>
                  </span>
                </NavLink>
              </li>
            </SupervisorRender>
            <SuperadminRender>
              <li>
                <NavLink
                  activeStyle={{
                    color: "black",
                  }}
                  activeClassName="active"
                  to={"/superadmin/offices"}
                >
                  <span className="icon">
                    <img src={offices} alt="" />
                  </span>
                  <span className="title">
                    <h3>Offices</h3>
                  </span>
                </NavLink>
              </li>
            </SuperadminRender>
          </ul>

          <div className="signout shadow-sm">
            <a onClick={this.showPopup}>
              <img className="me-2" src={arrow} alt="" />
              <span className="signout-text">Sign Out</span>
            </a>
          </div>
        </div>
        <Link
          className="login shadow-sm"
          to={
            localStorage.getItem("role") == "admin"
              ? "/digitaez/profile"
              : "" + localStorage.getItem("role") == "supervisor"
              ? "/supervisor/profile"
              : ""
          }
        >
          <img
            className="signed-profile"
            src={
              this.state.current_user.photo === "no-photo.jpg"
                ? profile
                : `${process.env.REACT_APP_BASEURL}/uploads/profile/${this.state.current_user.photo}`
            }
            alt=""
          />

          <p>{this.state.current_user.username}</p>

          {/* <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> */}
        </Link>
        <div className="wrapper">
          <div className="main_content">
            <Switch>
              <SuperadminRoute
                path="/superadmin/offices"
                component={() => <Offices />}
              />
              <SupervisorRoute
                path="/supervisor/leave-requests"
                component={() => <Leave_Requests_Wrapper />}
              />
              <SupervisorRoute
                path="/supervisor/profile"
                component={() => (
                  <Profile_View current_user={this.state.current_user} />
                )}
              />
              <RouteRequiresLogin
                path="/digitaez/users"
                component={() => (
                  <Users current_user_id={this.state.current_user._id} />
                )}
              />

              <RouteRequiresLogin
                path="/digitaez/attendance"
                component={() => (
                  <Attendance current_user_id={this.state.current_user._id} />
                )}
              />

              {/* <RouteRequiresLogin
                path="/digitaez/dashboard"
                component={Dashboard}
              />
              <RouteRequiresLogin
                path="/digitaez/office-timings"
                component={Office_Timings}
              />
              <RouteRequiresLogin path="/digitaez/food" component={Food} />
              <RouteRequiresLogin
                path="/digitaez/digicoins"
                component={Digicoins}
              /> */}

              <RouteRequiresLogin
                path="/digitaez/profile"
                component={() => (
                  <Profile_View current_user={this.state.current_user} />
                )}
              />

              {/* <RouteRequiresLogin
                exact
                path="/digitaez"
                component={Dashboard}
              /> */}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
