import React, { Component } from "react";
import "../components/navbar/navbar.css";
import { Route, Link, NavLink } from "react-router-dom";
import "./users.css";
import Forget_Password from "../components/forget-password/forget-password";
import All_Users from "../components/all-users/all-users";
import Leave_Requests from "../components/leave-requests/leave-requests";
import Add_Users from "../components/add-users/add-users";
import Delete_Users from "../components/delete-users/delete-users";
import Edit_Users from "../components/edit-users/edit-users";
import ReactPaginate from "react-paginate";
// import RouteRequi from "../components/auth/authLink";
import gear from "../images/gear.svg";
import Search_Bar from "../components/search-bar/search-bar";
import All_Users_Wrappers from "../wrappers/Users/All_Users_Wrapper";
import Delete_Users_Wrapper from "../wrappers/Users/Delete_Users_Wrapper";
import Forgot_Password_Wrapper from "../wrappers/Users/Forgot_Password_Wrapper";
import Leave_Requests_Wrapper from "../wrappers/Users/Leave_Reqeusts_Wrapper";
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      supervisorInfo: [],
      isLoaded: false,
      searchTerm: "",
      totalPages: 0,
      filterTerm: "Active",
      currrent_page: 1,
    };
  }

  componentDidMount() {
    //calling all users
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/v1/users?limit=10&page=${this.state.currrent_page}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({
          isLoaded: true,
          totalPages: responseJson.totalPages,
          userInfo: responseJson.data,
        });
      });

    //calling all supervisors
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/v1/users?role=supervisor&status=Active`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        this.setState({
          supervisorInfo: responseJson.data,
        });
      });
  }

  handlePagination = (e) => {
    const setPage = e.selected + 1;
    this.state.currrent_page = setPage;
    this.componentDidMount();
  };

  refreshPage = () => {
    this.componentDidMount();
  };

  handleChange = (e) => {
    this.setState({ searchField: e.target.value });
  };

  searchUser = (text) => {
    console.log(text);
    if (text !== "") {
      fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/users?${
          text !== "" ? `username=${text}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({
            isLoaded: true,
            totalPages: responseJson.totalPages,
            userInfo: responseJson.data,
          });
        });
    } else this.componentDidMount();
  };

  render() {
    var { isLoaded, userInfo, rerender } = this.state;
    return (
      <React.Fragment>
        {/* {rerender ? this.forceUpdate() : ""} */}
        <div>
          <ul className="list">
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                exact
                to={"/digitaez/users"}
                className="shadow"
              >
                <h3>All Users</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/users/add-users"}
                className="shadow"
                href=""
              >
                <h3>Add Users</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/users/delete-users"}
                className="shadow"
                href=""
              >
                <h3>Delete Users</h3>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/users/forget-password"}
                className="shadow"
                href=""
              >
                <h3>Forgot Password</h3>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/users/leave-requests"}
                className="shadow"
                href=""
              >
                <h3>Leave Requests</h3>
              </NavLink>
            </li>
          </ul>
        </div>

        <Route
          path="/digitaez/users/forget-password"
          component={() => (
            <Forgot_Password_Wrapper
              userInfo={userInfo}
              key={userInfo.id}
              photo={userInfo.photo}
              username={userInfo.username}
              designation={userInfo.designation}
              email={userInfo.email}
              coins={userInfo.coins}
              isLoaded={this.state.isLoaded}
              status={userInfo.status}
            />
          )}
        />
        <Route
          path="/digitaez/users/add-users"
          component={() => (
            <Add_Users
              supervisorInfo={this.state.supervisorInfo}
              current_user_id={this.props.current_user_id}
            />
          )}
        />
        <Route
          path="/digitaez/users/edit-users"
          component={() => (
            <Edit_Users supervisorInfo={this.state.supervisorInfo} />
          )}
        />

        <Route
          path="/digitaez/users/leave-requests"
          component={() => <Leave_Requests_Wrapper />}
        />

        <Route
          exact
          path="/digitaez/users/delete-users"
          render={() => (
            <div>
              <div className="container">
                <div className="search-container mt-4 mb-4 justify-content-start">
                  <div className="col-8">
                    <Search_Bar
                      searchUser={this.searchUser}
                      refreshPage={this.refreshPage}
                    />
                  </div>
                  <div className="col-2">
                    <select
                      name="user-filter"
                      className="form-control-lg user-filter shadow "
                      onChange={(change) => {
                        this.state.filterTerm = change.target.value;
                        console.log(this.state.filterTerm);
                        this.componentDidMount();
                      }}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <Delete_Users_Wrapper
                userInfo={userInfo}
                key={userInfo._id}
                id={userInfo._id}
                photo={userInfo.photo}
                filterTerm={this.state.filterTerm}
                username={userInfo.username}
                designation={userInfo.designation}
                email={userInfo.email}
                coins={userInfo.coins}
                isLoaded={this.state.isLoaded}
                status={userInfo.status}
                refreshPage={this.refreshPage}
              />
              <div className="pagination-item">
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={this.handlePagination}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={this.state.totalPages}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active-link"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          )}
        />

        <Route
          exact
          path="/digitaez/users"
          render={() => (
            <div>
              <div className="container">
                <div className="search-container mt-4 mb-4 justify-content-start">
                  <div className="col-8">
                    <Search_Bar
                      searchUser={this.searchUser}
                      refreshPage={this.refreshPage}
                    />
                  </div>
                  <div className="col-2">
                    <select
                      name="user-filter"
                      className="form-control-lg user-filter shadow "
                      onChange={(change) => {
                        this.state.filterTerm = change.target.value;
                        console.log(this.state.filterTerm);
                        this.componentDidMount();
                      }}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <All_Users_Wrappers
                userInfo={userInfo}
                role={userInfo.role}
                key={userInfo.id}
                handleChange={this.handleChange}
                photo={userInfo.photo}
                filterTerm={this.state.filterTerm}
                username={userInfo.username}
                designation={userInfo.designation}
                email={userInfo.email}
                coins={userInfo.coins}
                isLoaded={this.state.isLoaded}
              />
              <div className="pagination-item">
                <ReactPaginate
                  nextLabel="next >"
                  onPageChange={this.handlePagination}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={this.state.totalPages}
                  previousLabel="< previous"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active-link"
                  activeLinkClassName="active-link"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          )}
          // component={() => (
          //   <All_Users_Wrappers
          //     userInfo={userInfo}
          //     key={userInfo.id}
          //     handleChange={this.handleChange}
          //     photo={userInfo.photo}
          //     username={userInfo.username}
          //     designation={userInfo.designation}
          //     email={userInfo.email}
          //     coins={userInfo.coins}
          //     isLoaded={this.state.isLoaded}
          //   />
          // )}
        />
      </React.Fragment>
    );
  }
}

export default Users;
