import React, { Component, useState, useEffect } from "react";
import { Link, Route, useHistory, Redirect } from "react-router-dom";
import eye from "../images/eye.svg";
import eye_slash from "../images/eye-slash.svg";
import { userSchema } from "../Validation/sign-valid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./sign-in.css";
import Signlogo from "../images/logo-sign.svg";
import RouteRequiresLogin from "../components/auth/authLink";
import Dashboard from "./dashboard";
import { toast } from "react-hot-toast";

function Sign_In() {
  //  [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   ClearToken();
  // });
  const ClearToken = () => {
    localStorage.clear();
  };

  const [error, setError] = useState("");

  const HandleForm = (data) => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);
      setError();
      fetch(`${process.env.REACT_APP_BASEURL}/api/v1/auth/portalLogin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setDisabled(false);
          if (responseJson.success == true) {
            toast.success(`Login Successful`, {
              id: loader,
            });
            localStorage.setItem("token", responseJson.token);
            localStorage.setItem("role", responseJson.user.role);
            console.log("Successfully Logged In");
            if (localStorage.getItem("role") == "admin")
              history.push("/digitaez/users");
            else if (localStorage.getItem("role") == "supervisor")
              history.push("/supervisor/leave-requests");
            else if (localStorage.getItem("role") == "superAdmin")
              history.push("/superadmin/offices");

            console.log(localStorage.getItem("role"));
          } else {
            setError(responseJson.error);
            toast.error(`Unable to sign in`, {
              id: loader,
            });
          }
        })
        .catch((err) => {
          toast.error(`Unable to sign in`, {
            id: loader,
          });
        });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  return (
    <div className="sign-in-background">
      <div className="sign-in-wrapper">
        <div>
          <img className="signlogo" src={Signlogo} alt="" />
        </div>

        <form onSubmit={handleSubmit(HandleForm)}>
          <div className="sign-forms">
            <div className="error-hndl">
              <input
                type="text"
                id="email"
                name="email"
                className="form-control mt-2  signform"
                placeholder="Enter Your Email"
                autoComplete="off"
                {...register("email")}
              />
            </div>
            <div className="error-hndl">
              <div className="eye-container">
                <img
                  className="eye"
                  src={showPass ? eye_slash : eye}
                  onClick={() => {
                    setShowPass((prevCheck) => !prevCheck);
                  }}
                  alt=""
                />
                <input
                  type={showPass ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control password-field mt-2 signform"
                  placeholder="Enter Your Password"
                  autoComplete="off"
                  {...register("password")}
                />
              </div>
              <div className="error-hndl">
                <p className=" mt-4 error-msg">{errors.email?.message}</p>
                <p className=" mt-4 error-msg">{errors.password?.message}</p>
                <p className="error-msg">{error}</p>
              </div>
            </div>
          </div>
          <div>
            <input type="submit" className="sign-inbtn  mt-3"></input>
          </div>
        </form>
        <div>
          <Link to={"/password-reset"} href="">
            Forgot Password?
          </Link>
          <RouteRequiresLogin path="/digitaez" component={Dashboard} />
        </div>
      </div>
    </div>
  );
}

export default Sign_In;
