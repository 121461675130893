import React, { Component } from "react";
import "./office-timings-com.css";
class Office_Timings_Com extends Component {
  state = {};
  render() {
    return (
      <div className="office-wrapper">
        <div className="office-smwrapper shadow">
          <div className="office-flexcontainer">
            <div className="date">
              <h1>15</h1>
              <h2>AUG</h2>
            </div>
            <div>
              <h2>Monday</h2>
              <div className="time">
                <div>
                  <h3>ON</h3>
                  <h3>10:00 AM</h3>
                </div>
                <div>
                  <h3 className="ms-5">OFF</h3>
                  <h3 className="ms-5">6:00 PM</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="two-buttons">
          <a href="">
            <h3>Edit Timings</h3>
          </a>
          <a href="">
            <h3>Notify Users</h3>
          </a>
        </div>
      </div>
    );
  }
}

export default Office_Timings_Com;
