import React, { Component } from "react";
import burger from "../../images/burger.png";
import coin from "../../images/coin.svg";
import "./redeem-requests.css";
class Redeem_Requests extends React.Component {
  render() {
    return (
      <div className="redeemreq-wrapper">
        <div className="redeemreq-img-wrapper">
          <img className="redeemreq-item" src={burger} alt="" />
        </div>
        <div className="redeemreq-textwrap">
          <h2>Item Name</h2>
          <h3>
            <img className="redeemreq-coin me-2" src={coin} alt="" /> 250 DGC
          </h3>
          <h3>By: Shemoel Shakil</h3>
        </div>
        <div className="redeemreq-buttons">
          <a className="redeemreq-bluebtn" href="">
            Approve
          </a>
          <a className="mt-2 redeemreq-redbtn" href="">
            Deny
          </a>
        </div>
      </div>
    );
  }
}

export default Redeem_Requests;
