import React from "react";
import { Redirect, Route } from "react-router-dom";
import Sign_In from "../../containers/sign-in";
import { userSchema } from "../../Validation/sign-valid";
import loginCheck from "./auth1";

function SupervisorRoute({ component: Component, ...restOfProps }) {
  let isAuthenticated = null;
  if (localStorage.getItem("token") == null || undefined) {
    isAuthenticated = false;
  } else if (localStorage.getItem("role") == "supervisor")
    isAuthenticated = true;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}
export default SupervisorRoute;
