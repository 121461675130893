import React, { Component } from "react";
import "./all-users-modal.css";
import profile from "../../images/profile.jpg";

const All_Offices_Modal = (props) => {
  if (!props.show) {
    return null;
  }
  //   const convertToInt = (num) => {
  //     const updatedNum = parseInt(num);
  //     return updatedNum;
  //   };
  return (
    <div id="modal-root" className="custom-modal" onClick={props.onClose}>
      <div className="modal-content">
        <img
          className="profile-img user-modal-img mb-3"
          src={
            props.image === "no-image.jpg"
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/offices/${props.image}`
          }
          alt=""
        />
        <p>
          <strong>Full Name:</strong> {props.name}
        </p>
        <p>
          <strong>Email:</strong> {props.email}
        </p>
        <p>
          <strong>Address:</strong> {props.address}
        </p>
        <p>
          <strong>Phone:</strong> {props.phone}
        </p>

        <p>
          <strong>Status:</strong> {props.status}
        </p>
        {/* <p>
          <strong>Hours Worked:</strong> {convertToInt(props.hoursWorked)}
        </p> */}
      </div>
    </div>
  );
};

export default All_Offices_Modal;
