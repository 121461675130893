import React, { Component, useState } from "react";
import { userSchema } from "../../Validation/forgot-ps-valid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import "./forgotps.css";
import { useHistory } from "react-router";
import Signlogo from "../../images/lock.svg";
import { toast } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

const Forgot_Password = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const HandleForm = (data) => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);
      //resetting errors array

      setError();
      fetch(`${process.env.REACT_APP_BASEURL}/api/v1/auth/forgotPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setError(responseJson.status);
          setDisabled(false);
          if (responseJson.success == true) {
            toast.success(`Email Sent`, {
              id: loader,
            });
            history.push("/email-sent");
          } else setError(responseJson.error);
        })
        .catch((err) => {
          toast.success(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  return (
    <div className="sign-in-background-ps">
      <div className="sign-in-wrapper-ps">
        <div>
          <img src={Signlogo} alt="" />
        </div>
        <div className="ps-text">
          <h2>Forgot Password</h2>
          <h3>Enter your Email address for instructions to be sent</h3>

          <form onSubmit={handleSubmit(HandleForm)}>
            <div className="sign-forms mt-4 ">
              <div className="error-hndl">
                <input
                  type="text"
                  id="email"
                  required
                  name="email"
                  className="form-control signform "
                  placeholder="johndoe@domain.com"
                  autoComplete="off"
                  {...register("email")}
                />
              </div>
              <div className="error-hndl">
                <p className=" mt-4 error-msg">{errors.email?.message}</p>
                <p className=" mt-4 error-msg">{errors.password?.message}</p>
                <p className="error-msg">{error}</p>
              </div>
            </div>
            <div>
              <input
                type="submit"
                value="Send"
                className="/*sign-inbtn*/ sign-inbtn-ps mb-2  mt-3"
              ></input>
            </div>
          </form>
        </div>

        <div>
          {/* <Link
            to={"/email-sent"}
            className="sign-inbtn-ps mb-2 shadow-sm"
            href=""
          >
            Send
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Forgot_Password;
