import React, { Component } from "react";
import Office_Timings_Com from "../components/office-timings/office-timings-com";
import "./office-timings.css";
class Office_Timings extends Component {
  state = {};
  render() {
    return (
      <div className="office-container">
        <Office_Timings_Com />
        <Office_Timings_Com />
        <Office_Timings_Com />
        <Office_Timings_Com />
        <Office_Timings_Com />
        <Office_Timings_Com />
      </div>
    );
  }
}

export default Office_Timings;
