import React, { Component, useState } from "react";
import search from "../../images/search.svg";
import "./search-bar.css";

const Search_Bar = ({ searchUser, refreshPage }) => {
  const [searchTerm, setSearchTerm] = useState();

  const onChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value == "") refreshPage();
    console.log(searchTerm);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    searchUser(searchTerm);
  };

  // const handleEnter = (e) => {
  //   if (e.key === "Enter") {
  //     console.log("do validate");
  //   }
  // };

  return (
    <div className="form-format">
      <label for="search-dish">
        <h3 className="text-nowrap">Search Here:</h3>
      </label>
      <form class="input-group search-with-icon" onSubmit={onSubmit}>
        <input
          type="text"
          size={10}
          class="form-control ms-lg-3 form-rounded-search"
          placeholder="Search by name"
          name="search_item"
          onChange={onChange}
          // handleEnter={handleEnter}
        />
        <div class="input-group-append">
          <button class="btn btn-secondary search-btn" type="submit">
            <img src={search} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search_Bar;
