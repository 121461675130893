import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import All_Users from "../../components/all-users/all-users";
import Search_Bar from "../../components/search-bar/search-bar";
import gear from "../../images/gear.svg";

const All_Users_Wrappers = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [filterTerm, setFilterTerm] = useState(true);

  // const filterSelecter = (e) => {
  //   console.log(filterTerm);
  // };

  return (
    <div>
      <div>
        {props.userInfo == "" ? (
          props.isLoaded == true ? (
            <div className="nodata">
              <img src={nodata} alt="" />
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loader">
              <TailSpin color={"#34b0dd"} />
            </div>
          )
        ) : (
          <div className="wrapper-array">
            {props.userInfo
              .filter((userInfo) => {
                if (
                  userInfo.status === "Active" &&
                  props.filterTerm == "Active"
                ) {
                  if (searchTerm === "") {
                    return userInfo;
                  } else if (
                    userInfo.username
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return userInfo;
                  }
                } //search implemented for frontend pagination
                if (
                  userInfo.status == "Inactive" &&
                  props.filterTerm == "Inactive"
                ) {
                  if (searchTerm === "") {
                    return userInfo;
                  } else if (
                    userInfo.username
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return userInfo;
                  }
                }
              })
              .map((userInfo) => (
                <All_Users
                  key={userInfo._id}
                  joiningDate={userInfo.JoiningDate}
                  hoursWorked={userInfo.hoursWorked}
                  id={userInfo._id}
                  role={userInfo.role}
                  photo={userInfo.photo}
                  username={userInfo.username}
                  designation={userInfo.designation}
                  email={userInfo.email}
                  leaves={userInfo.leaves}
                  coins={userInfo.coins}
                  supervisedBy={userInfo.supervisedBy}
                  status={userInfo.status}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default All_Users_Wrappers;
