import profile from "../../images/profile2.jpg";
import "./leaveReq_modal.css";
import cross from "../../images/cross-sm.svg";

const LeaveReq_Modal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="custom-modal">
      <div className="modal-content">
        <img className="close-btn" onClick={props.onClose} src={cross} alt="" />
        <div className="leave-req-modal">
          <div className="modal-row1">
            {/* <img className="shadow profile-img" src={profile} alt="" /> */}
            <h2 /*className="ms-3"*/>{props.username}</h2>
          </div>
          <div className="mt-3">
            <div className="leavereq-scroll">
              <p>
                <strong>Reason: </strong>

                {props.reason}
              </p>
            </div>
            <div className="modal-row1">
              <div>
                <p>
                  <strong>Type:</strong> {props.type}
                </p>
                <p>
                  <strong>Start:</strong> {props.startDate}
                </p>
                <p>
                  <strong>End:</strong> {props.endDate}
                </p>
                <p>
                  <strong>No. of Leaves:</strong> {props.days} day(s)
                </p>
                <a
                  className={
                    props.document == "no-document.jpg" ? "hidden" : ""
                  }
                  target="_blank"
                  href={`${process.env.REACT_APP_BASEURL}/uploads/documents/${props.document}`}
                >
                  {" "}
                  <p>Click here for attachments</p>
                </a>
                {/* <p>
                <strong>Leaves Remaining:</strong> 52 Days
              </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveReq_Modal;
