import React, { Component } from "react";
import profile from "../../images/profile.jpg";
import "./employee-month.css";
class Employee_Month extends Component {
  state = {};
  render() {
    return (
      <div className="em-day-wrapper">
        <div className="em-day-date">
          <h1 className="employee-month">
            2021 <br />
            <h2>AUG</h2>
          </h1>
        </div>
        <div className="em-day-profile shadow">
          <img src={profile} alt="" />
        </div>
        <div className="em-day-text">
          <h3>
            Name Surname <br /> <div classname="bold-text">200 Digicoins</div>
          </h3>
        </div>
      </div>
    );
  }
}

export default Employee_Month;
