import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Delete_Offices from "../../../components/delete-offices/delete-offices";
// import All_Offices from "../../../components/all-offices/all-offices";
import nodata from "../../../images/nodata.svg";

const All_Offices_Wrapper = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div>
      <div>
        {props.officeInfo == "" ? (
          props.isLoaded == true ? (
            <div className="nodata">
              <img src={nodata} alt="" />
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="loader">
              <TailSpin color={"#34b0dd"} />
            </div>
          )
        ) : (
          <div className="wrapper-array">
            {props.officeInfo
              .filter((officeInfo) => {
                if (
                  officeInfo.status === "Active" &&
                  props.filterTerm == "Active"
                ) {
                  if (searchTerm === "") {
                    return officeInfo;
                  } else if (
                    officeInfo.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return officeInfo;
                  }
                } //search implemented for frontend pagination
                if (
                  officeInfo.status == "Inactive" &&
                  props.filterTerm == "Inactive"
                ) {
                  if (searchTerm === "") {
                    return officeInfo;
                  } else if (
                    officeInfo.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return officeInfo;
                  }
                }
              })
              .map((officeInfo) => (
                <Delete_Offices
                  key={officeInfo._id}
                  joiningDate={officeInfo.JoiningDate}
                  hoursWorked={officeInfo.hoursWorked}
                  id={officeInfo._id}
                  role={officeInfo.role}
                  photo={officeInfo.photo}
                  refreshPage={props.refreshPage}
                  name={officeInfo.name}
                  designation={officeInfo.designation}
                  email={officeInfo.email}
                  leaves={officeInfo.leaves}
                  coins={officeInfo.coins}
                  supervisedBy={officeInfo.supervisedBy}
                  status={officeInfo.status}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default All_Offices_Wrapper;
