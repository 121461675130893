import React, { Component } from "react";
import "./donutgraph.css";
import { Doughnut, Chart } from "react-chartjs-2";
const Donutgraph = () => {
  return (
    <div>
      <div id="canvas" className="donut-main">
        <Doughnut
          data={{
            labels: ["Total Employees"],
            datasets: [
              {
                label: "People Present",
                data: [150],
                backgroundColor: ["rgb(9, 194, 183)"],
                borderColor: "rgb(0, 173, 239)",
                fill: false,
                borderWidth: 0,
                weight: 1000,
              },
            ],
          }}
          height={600}
          width={600}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            cutout: 73,

            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
        <div className="donut-2">
          <h4>Total Employees</h4>

          <h1>150</h1>
        </div>
      </div>
    </div>
  );
};

export default Donutgraph;
