import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import { useEffect } from "react/cjs/react.development";
import All_Users from "../../components/all-users/all-users";
import Delete_Users from "../../components/delete-users/delete-users";
import Search_Bar from "../../components/search-bar/search-bar";
import gear from "../../images/gear.svg";

const Delete_Users_Wrapper = (props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm({ searchTerm: e.target.value });
    console.log(searchTerm);
  };

  return (
    <div>
      {props.userInfo == "" ? (
        props.isLoaded == true ? (
          <div className="nodata">
            <img src={nodata} alt="" />
            <p>No Data Found</p>
          </div>
        ) : (
          <div className="loader">
            <TailSpin color={"#34b0dd"} />
          </div>
        )
      ) : (
        <div className="wrapper-array">
          {props.userInfo
            .filter((userInfo) => {
              if (
                userInfo.status === "Active" &&
                props.filterTerm == "Active"
              ) {
                if (searchTerm === "") {
                  return userInfo;
                } else if (
                  userInfo.username
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return userInfo;
                }
              } else if (
                userInfo.status == "Inactive" &&
                props.filterTerm == "Inactive"
              ) {
                if (searchTerm === "") {
                  return userInfo;
                } else if (
                  userInfo.username
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return userInfo;
                }
              }
            })

            .map((userInfo) => (
              <Delete_Users
                key={userInfo._id}
                id={userInfo._id}
                photo={userInfo.photo}
                username={userInfo.username}
                designation={userInfo.designation}
                email={userInfo.email}
                coins={userInfo.coins}
                status={userInfo.status}
                refreshPage={props.refreshPage}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Delete_Users_Wrapper;
