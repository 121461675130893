import React, { Component } from "react";
import "./linegraph.css";
import { Line, Chart } from "react-chartjs-2";

const Linegraph = (props) => {
  return (
    <div className="linegraph-wrapper">
      <Line
        data={{
          labels: ["M", "T", "W", "T", "F", "S", "S"],

          datasets: [
            {
              label: "People Present",
              data: props.attendance,

              borderColor: "rgb(19, 121, 182)",
              backgroundColor: "rgb(92, 197, 193, 0.7)",
              fill: "start",
              borderWidth: 0.5,
              tension: 0.4,
              pointRadius: 2,
            },
          ],
        }}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default Linegraph;
