import React, { Component, useState, setShow } from "react";
import profile from "../../images/profile.jpg";
import trash from "../../images/trash.svg";
import unban from "../../images/unban.svg";
import Users from "../../containers/users";
import { toast } from "react-hot-toast";
import Delete_Users_Modal from "../modals/delete-users-modal";

const Delete_Offices = (props) => {
  const [show, setShow] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const closePopup = () => {
    setTimeout(() => {
      setShow(false);
    }, 10);
  };

  const DeleteUser = (e) => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);
      const promise = fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/offices/${props.id}`,
        {
          method: `${props.status == "Active" ? "DELETE" : "POST"}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.success) {
            toast.success(`Office Status Updated`, {
              id: loader,
            });
          } else
            toast.error(`${responseJson.message}`, {
              id: loader,
            });
          console.log(responseJson);
          setDisabled(false);
          closePopup();
          props.refreshPage();
        })
        .catch((err) => {
          toast.error(`An error has occurred`);
        });
    }
  };

  return (
    <div className="user-card shadow">
      <Delete_Users_Modal
        onClose={closePopup}
        show={show}
        disabled={disabled}
        setDisabled={(data) => {
          setDisabled(data);
        }}
        id={props.id}
        refreshPage={props.refreshPage}
        name={props.name}
        method={DeleteUser}
        status={props.status}
      />
      <div className="padding ">
        {console.log(props.status)}
        <div className="card-content">
          <img
            src={
              props.photo != null
                ? `${process.env.REACT_APP_BASEURL}/uploads/offices/${props.photo}`
                : profile
            }
            alt=""
          />
          <div>
            <h2>{props.name}</h2>
            <h3>{props.designation}</h3>
          </div>
          <a onClick={() => setShow(true)} className="proceed-del">
            <img src={props.status == "Active" ? trash : unban} alt="" />
          </a>
        </div>
        <div>
          <p className="mt-3">Email: {props.email}</p>
        </div>
      </div>
    </div>
  );
};

export default Delete_Offices;
