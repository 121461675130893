import "./view-attendance.css";
import profile from "../../images/profile.jpg";
const View_Attendance = (props) => {
  const formatTime = (time) => {
    if (time) {
      var formattedTime = time.slice(11, 19);
      var hourEnd = formattedTime.indexOf(":");
      var H = +formattedTime.substr(0, hourEnd);
      var h = H % 12 || 12;
      var ampm = H < 12 || H === 24 ? " AM" : " PM";
      formattedTime = h + formattedTime.substr(hourEnd, 3) + ampm;
      return formattedTime;
    } else return null;
  };

  return (
    <div className="attend-wrapper shadow">
      <div className="attend-container">
        <img
          src={
            props.photo === "no-photo.jpg"
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/profile/${props.photo}`
          }
          alt=""
        />
        <div className="attend-text">
          <h2>{props.username}</h2>
          <h3>{props.designation}</h3>
          <div>
            <h3 className={props.startTime ? "" : "none"}>
              Start: {formatTime(props.startTime)}
            </h3>
            <h3 className={props.endTime ? "" : "none"}>
              End: {formatTime(props.endTime)}
            </h3>
          </div>

          <p
            className={
              props.status === "Present"
                ? "attend-green"
                : "" + props.status === "Absent"
                ? "attend-red"
                : ""
            }
          >
            {props.status}
          </p>
        </div>
      </div>
    </div>
  );
};

export default View_Attendance;
