import React, { Component } from "react";
import { toast } from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";
import nodata from "../../images/nodata.svg";
import View_Attendance from "../../components/view-attendance/view-attendance";
class Attendance_Day extends Component {
  constructor(props) {
    super(props);
    this.state = {
      present: [],
      absent: [],
      disabled: false,
      isLoaded: false,
      searchTerm: "",
    };
  }

  incrementDate = (date) => {
    var tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const offset = tomorrow.getTimezoneOffset();
    tomorrow = new Date(tomorrow.getTime() - offset * 60 * 1000);
    return tomorrow.toISOString().split("T")[0];
  };

  handleDate = (e) => {
    const selectedDate = e.target.value;
    console.log(selectedDate);
    const incrementedDate = this.incrementDate(selectedDate);
    console.log(incrementedDate);

    if (!this.state.disabled) {
      const loader = toast.loading("Loading");
      this.setState({
        disabled: true,
      });
      //resetting errors array

      fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/attendance?startTime[gte]="${selectedDate}"&startTime[lte]="${incrementedDate}`,
        {
          method: "GET",

          headers: {
            // "Content-Type": "application/json",
            // "Content-Length": data.toString().length + "",
            // Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({
            disabled: false,
            present: responseJson.data,
            isLoaded: true,
          });
          if (responseJson.success) {
            toast.success(`Data Loaded Successfully`, {
              id: loader,
            });
          } else {
            toast.error(`${responseJson.error}`, {
              id: loader,
            });
          }
        })
        .catch((err) => {
          toast.success(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  render() {
    return (
      <div className="attendance-day-wrapper">
        <div className="date-container mt-4 mb-4">
          <label for="JoiningDate" className="font-weight-bold">
            <h2>Select Date</h2>
          </label>
          <br></br>
          <input
            className="form-control w-25 shadow-sm form-rounded"
            required
            type="date"
            id="JoiningDate"
            name="JoiningDate"
            size="30"
            onChange={(e) => this.handleDate(e)}
          ></input>
        </div>
        <div>
          {this.state.present == "" ? (
            this.state.isLoaded == true ? (
              <div className="nodata">
                <img src={nodata} alt="" />
                <p>No Data Found</p>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="wrapper-array">
              {this.state.present.map((present) => (
                <View_Attendance
                  username={present.userId.username}
                  designation={present.userId.designation}
                  startTime={present.startTime}
                  endTime={present.endTime}
                  status={"Present"}
                  photo={present.userId.photo}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Attendance_Day;
