import * as yup from "yup";
import { format } from "date-fns";
const date = new Date();
export const userSchema = yup.object().shape({
  username: yup.string(),
  email: yup.string().email("Please enter a valid email address"),
  password: yup.string().min(6, "Password cannot be shorter than 6 characters"),
  designation: yup.string(),
  JoiningDate: yup.date(),
  role: yup.string(),
  photo: yup.string(),

  supervisedBy: yup.string(),
});
