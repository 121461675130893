import React, { Component } from "react";
import Toggle from "../toggle/toggle";
import coin from "../../images/coin.svg";
import "./allotment.css";
class Allotment extends React.Component {
  render() {
    return (
      <div className="allotment-wrapper shadow">
        <div className="allotment-switch">
          <h3 class="allotment-text">
            When Employee comes on between this time allot him this number of
            Digicoins
          </h3>
          <Toggle />
        </div>
        <div className="allotment-buttons">
          <div>
            <h3>MIN</h3>
            <a className="allotment-borderbtn" href="">
              10:00 AM
            </a>
          </div>
          <div>
            <h3>MAX</h3>
            <a className="allotment-borderbtn" href="">
              11:30 AM
            </a>
          </div>
          <div className="allotment-coindiv">
            <h3>DGC</h3>
            <a className="allotment-coin" href="">
              <img className="me-1" src={coin} alt="" />
              100 DGC
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Allotment;
