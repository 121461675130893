import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  NavLink,
} from "react-router-dom";
import "./attendance.css";
import RouteRequiresLogin from "../components/auth/authLink";
import Absent_Wrapper from "../wrappers/Users/Absent_Wrapper";
import Attendance_Day from "../wrappers/Users/Attendance_Day_Wrapper";
import Present_Wrapper from "../wrappers/Users/Present_Wrapper";
import Working_Hours_Wrapper from "./../wrappers/Users/Working_Hours_Wrapper";
class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      present: [],
      absent: [],
      isLoaded: false,
      searchTerm: "",
    };
  }

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/v1/attendance/getAttendanceStatus`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        this.setState({
          isLoaded: true,
          present: responseJson.data.presentUser,
          absent: responseJson.data.absentUser,
        });
      });

    console.log("done");
  }

  refreshPage = () => {
    this.componentDidMount();
  };

  handleChange = (e) => {
    this.setState({ searchField: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        {/* {rerender ? this.forceUpdate() : ""} */}
        <div>
          <ul className="list-food">
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                exact
                to={"/digitaez/attendance"}
                className="shadow"
              >
                <h3>Present</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/attendance/absent"}
                className="shadow"
                href=""
              >
                <h3>Absent</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/attendance/working-hours"}
                className="shadow"
                href=""
              >
                <h3>Working Hours</h3>
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  background: "#34b0dd",
                }}
                to={"/digitaez/attendance/attendance-by-day"}
                className="shadow"
                href=""
              >
                <h3>Attendance By Day</h3>
              </NavLink>
            </li>
          </ul>
        </div>

        <div>
          <RouteRequiresLogin
            path="/digitaez/attendance/attendance-by-day"
            component={() => <Attendance_Day />}
          />

          <RouteRequiresLogin
            path="/digitaez/attendance/working-hours"
            component={() => <Working_Hours_Wrapper />}
          />
          <RouteRequiresLogin
            path="/digitaez/attendance/absent"
            component={() => (
              <Absent_Wrapper
                absent={this.state.absent}
                isLoaded={this.state.isLoaded}
              />
            )}
          />
          <RouteRequiresLogin
            path="/digitaez/attendance"
            exact
            component={() => (
              <Present_Wrapper
                present={this.state.present}
                isLoaded={this.state.isLoaded}
              />
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Attendance;
