import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react/cjs/react.development";
import profile from "../../images/profile.jpg";
import All_Offices_Modal from "../modals/all-offices-modal";

const All_Offices = (props) => {
  const [isActive, setIsActive] = useState("");
  // useEffect(() => {
  //   if (props.status === "Active") setIsActive(true);
  //   else setIsActive(false);
  // });

  const [show, setShow] = useState(false);
  const closePopup = () => {
    setTimeout(() => {
      setShow(false);
    }, 10);
  };

  return (
    <div className="user-card shadow">
      <All_Offices_Modal
        key={props.id}
        name={props.name}
        email={props.email}
        address={props.address}
        phone={props.phone}
        hoursWorked={props.hoursWorked}
        leaves={props.leaves}
        role={props.role}
        joiningDate={props.joiningDate}
        status={props.status}
        image={props.image}
        phone={props.phone}
        cnic={props.cnic}
        onClose={closePopup}
        show={show}
      />
      <div className="padding ">
        <div className="card-content">
          <img
            src={
              props.image === "no-image.jpg"
                ? profile
                : `${process.env.REACT_APP_BASEURL}/uploads/offices/${props.image}`
            }
          />
          <div>
            <h2>{props.name}</h2>
            {/* <h3>{props.designation}</h3> */}
          </div>
          <div className="dot-container">
            <span
              id="status-check"
              className={props.status === "Active" ? "dot-green" : "dot-red"}
            ></span>
            <Link
              className="edit-profile"
              to={{
                pathname: "/superadmin/offices/edit-offices",

                state: {
                  id: props.id,
                  image: props.image,
                  name: props.name,
                  email: props.email,
                  address: props.address,
                  phone: props.phone,
                },
              }}
            >
              <p className="mt-4">Edit Profile</p>
            </Link>
            <a className="edit-profile" onClick={() => setShow(true)}>
              <p>View Profile</p>
            </a>
          </div>
        </div>
        <div>
          <p className="mt-3">Email: {props.email}</p>
          {/* <p>Digicoins: {props.coins}</p> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(All_Offices);
