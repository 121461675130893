import React, { Component, useState } from "react";
import "./sign-out-modal.css";
import { Link } from "react-router-dom";
import cross from "../../images/cross.svg";
import { toast } from "react-hot-toast";

const Sign_Out_Modal = (props) => {
  if (!props.show) {
    return null;
  }

  const ClearToken = () => {
    fetch(`${process.env.REACT_APP_BASEURL}/api/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
    localStorage.clear("token");
  };

  return (
    <div className="custom-modal">
      {console.log(props.show)}
      <div className="del-users-modal-content">
        <div className="del-users-modal-text">
          <h2>Are you sure you want to sign out?</h2>
          <div className="mt-4 del-modal-btns">
            <Link
              to={"/sign-in"}
              onClick={ClearToken}
              className="red-hover w-50 delete-btn"
            >
              Sign Out
            </Link>
            <a onClick={props.onClose} className="black-hover w-50 accept-btn">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sign_Out_Modal;
