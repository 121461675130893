import React, { Component } from "react";
import "./dishes.css";
import karahi from "../../images/karahi.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

const Dishes = (props) => {
  return (
    <div className="wrapper-dish shadow">
      <div className="dishflex">
        <div className="karahi">
          <img src={karahi} alt="" />
        </div>
        <div>
          <div className="karahi-text">
            <h2>{props.name}</h2>
            <p>{props.desc}</p>
          </div>
          <div className="karahi-buttons">
            <a href="">
              <h3 className="karahi-delete">Delete</h3>
            </a>
            <Link to={"/food/edit-dish"}>
              <h3 className="karahi-edit">Edit Dish</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dishes;
