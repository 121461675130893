import React, { Component, useState, useEffect } from "react";
import profile from "../../images/profile-edit.jpg";
import { userSchema } from "../../Validation/edit-user-valid";
import { useForm } from "react-hook-form";
import eye from "../../images/eye.svg";
import eye_slash from "../../images/eye-slash.svg";
import randompas from "../../images/randompas.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Errors_Display from "./../errors-display/errors-display";
import Display_Supervisor from "../display-supervisor/display-supervisor";
import "./edit-users.css";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Edit_Users = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    id,
    photo,
    username,
    email,
    designation,
    role,
    supervisedBy,
    leaves,
  } = props.location.state;

  const [image_file, setImageFile] = useState();
  const [form_error, setForm_error] = useState();
  const [role_check, setRole_check] = useState();
  const [showPass, setShowPass] = useState(false);
  const [randomPass, setRandomPass] = useState();
  const [disabled, setDisabled] = useState(false);
  const [current_supervisor, setCurrent_supervisor] = useState();

  const splitError = () => {
    if (form_error) {
      const array = form_error.split(",");
      console.log(array);
      return array.map((data, i) => (
        <Errors_Display array={array[i]} index={i} />
      ));
    }
  };

  const generate_random = () => {
    var randomstring = Math.random().toString(36).slice(-8);
    setValue("password", randomstring);
    setShowPass(true);
    // setRandomPass(randomstring);
  };

  const resetForm = () => {
    document.getElementById("editusers").reset();
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  //submitting API

  const HandleForm = (data) => {
    if (!disabled) {
      console.log(data);
      const loader = toast.loading("Loading");
      setDisabled(true);
      //resetting errors array
      setForm_error("");
      data["file"] = image_file;

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      //adding image

      // formData.append("file", image_file);

      //removing supervisedby if user is not an employee
      // if (role_check === "employee") {
      //   formData.append("supervisedBy", data.supervisedBy);
      // }
      // formData.append("officeId", "6125615f221423d73cfd01eb");

      // formData.append("username", data.username);
      // formData.append("password", data.password);s
      // formData.append("role", data.role);

      // formData.append("designation", data.designation);
      // formData.append("email", data.email);
      // formData.append("leaves", data.leaves);
      // formData.append("JoiningDate", data.JoiningDate);
      // formData.append("file", image_file);

      // data["officeId"] = "6125660247b6eaacecff03bc";
      // console.log(data);

      fetch(`${process.env.REACT_APP_BASEURL}/api/v1/users/${id}`, {
        method: "PUT",

        headers: {
          // "Content-Type": "application/json",
          // "Content-Length": data.toString().length + "",
          // Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setDisabled(false);
          if (responseJson.success) {
            toast.success(`User updated successfully`, {
              id: loader,
            });

            resetForm();
          } else {
            setForm_error(responseJson.error);
            toast.error(`${responseJson.error}`, {
              id: loader,
            });
          }
        })
        .catch((err) => {
          toast.error(`An error has occurred`, {
            id: loader,
          });
        });
    }
  };

  // //calling current supervisor
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASEURL}/api/v1/users?_id=${supervisedBy}`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log(responseJson);
  //       setCurrent_supervisor(responseJson.data[0].username);
  //     });
  // }, []);

  return (
    <div className="bigwrapper shadow">
      <div className="pt-5 second-wrapper">
        <form
          enctype="multipart/form-data"
          id="editusers"
          onSubmit={handleSubmit(HandleForm)}
        >
          <div className="seperator">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImageFile(e.target.files[0]);
              }}
              className="form-control file-upload"
              name="photo"
            ></input>
          </div>
          {/* <input
            type="file"
            id="picture"
            name="picture"
            // {...register("picture")}
          ></input> */}

          <div className="seperator">
            <div>
              <label for="username">Full Name</label>
              <br></br>
              <input
                className="form-control  mt-2 form-rounded"
                type="text"
                value={props.data}
                placeholder={username}
                id="username"
                name="username"
                size="30"
                {...register("username")}
              ></input>
              <br></br>

              <label for="designation">Designation</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="text"
                placeholder={designation}
                id="designation"
                name="designation"
                size="30"
                {...register("designation")}
              ></input>
              <br></br>

              <label for="supervisedBy">Supervised By</label>
              <br></br>
              <select
                name="supervisedBy"
                className="form-control mt-2 form-rounded"
                disabled={role_check !== "admin" ? false : true}
                id="supervisedBy"
                {...register("supervisedBy")}
              >
                {" "}
                <option value="" disabled selected hidden>
                  {supervisedBy ? supervisedBy.username : "None"}
                </option>
                {role_check !== "admin" ? (
                  props.supervisorInfo.map((supervisor) => (
                    <Display_Supervisor
                      name={supervisor.username}
                      _id={supervisor._id}
                    />
                  ))
                ) : (
                  <option value="">None</option>
                )}
              </select>
              <br></br>
              <label for="password">Password</label>
              <div className="eye-container">
                <img
                  className="randompas-add-users"
                  src={randompas}
                  onClick={() => generate_random()}
                  alt=""
                />
                <img
                  className="eye-add-users"
                  src={showPass ? eye_slash : eye}
                  onClick={() => {
                    setShowPass((prevCheck) => !prevCheck);
                  }}
                  alt=""
                />
                <input
                  className="form-control password-field mt-2 form-rounded"
                  required
                  autocomplete="new-password"
                  type={showPass ? "text" : "password"}
                  value={randomPass}
                  onChange={(e) => setRandomPass(e.target.value)}
                  // onInput={(e) => setRandomPass(e.target.value)}
                  id="password"
                  name="password"
                  size="20"
                  {...register("password")}
                />
              </div>
            </div>
            <div>
              {/* <label for="JoiningDate">Joining Date</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="date"
                id="JoiningDate"
                name="JoiningDate"
                size="30"
                {...register("JoiningDate")}
              ></input>
              <br></br> 
              <label for="pw">Password</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="password"
                id="pw"
                name="pw"
                size="30"
                {...register("password")}
              ></input>
              <br></br> */}
              <label for="role">Role</label>
              <br></br>
              <select
                name="role"
                onClick={(e) => {
                  setRole_check(e.target.value);
                }}
                className="form-control mt-2 form-rounded"
                id="role"
                {...register("role")}
              >
                {" "}
                <option value="" disabled selected hidden>
                  {role}
                </option>
                <option value="employee">Employee</option>
                <option value="admin">Admin</option>
                <option value="supervisor">Supervisor</option>
              </select>
              <br></br>
              <label for="leaves">Leaves</label>
              <br></br>
              <input
                name="leaves"
                placeholder={leaves}
                className="form-control mt-2 form-rounded"
                min="0"
                type="number"
                id="leaves"
                {...register("leaves")}
              ></input>
              <br></br>
              <label for="lname">Email</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="text"
                id="email"
                placeholder={email}
                name="email"
                size="30"
                {...register("email")}
              ></input>
            </div>
          </div>
          <div className="submit-form pb-4">
            <input
              className=" me-3 shadow submit-btn submit-btngrey"
              type="reset"
              value="Discard"
            ></input>
            <input
              className=" submit-btn shadow submit-btnblue "
              type="submit"
              value="Submit"
            ></input>
          </div>
        </form>
        <div className="">
          <h3 className={errors.email?.message ? "red" : "hide"}>
            {errors.email?.message}
          </h3>

          <h3 className={errors.password?.message ? "red" : "hide"}>
            {" "}
            {errors.password?.message}
          </h3>

          <h3 className={errors.cnic?.message ? "red" : "hide"}>
            {" "}
            {errors.cnic?.message}
          </h3>

          <h3 className={errors.phone_no?.message ? "red" : "hide"}>
            {errors.phone_no?.message}
          </h3>
          <h3
            className={errors.password_confirmation?.message ? "red" : "hide"}
          >
            {errors.password_confirmation?.message}
          </h3>
          {form_error ? splitError(form_error) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Edit_Users);
