import React, { Component, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "./App.css";
import NavBar from "./components/navbar/navbar";
import Sign_In from "./containers/sign-in";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Forgot_Password from "./components/sign-in/forgotps";
import Psreq_Sent from "./components/sign-in/psreq-sent";
import RouteRequiresLogin from "./components/auth/authLink";
import SupervisorRoute from "./components/auth/supervisor-route";
import SuperadminRoute from "./components/auth/superadmin-route";

class App extends Component {
  state = {};

  componentDidMount() {
    document.title = "Digitaez";
  }

  render() {
    return (
      <Router>
        <Toaster />
        <Switch>
          <RouteRequiresLogin path="/digitaez" component={NavBar} />
          <SuperadminRoute path="/superadmin" component={NavBar} />
          <SupervisorRoute path="/supervisor" component={NavBar} />
          <Route path="/password-reset" component={Forgot_Password} />
          <Route path="/email-sent" component={Psreq_Sent} />
          <Route path="/" component={Sign_In} />
        </Switch>
      </Router>
    );
  }
}

export default App;
