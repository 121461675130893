import { useState } from "react";
import { useEffect } from "react/cjs/react.development";
import All_Users from "../../components/all-users/all-users";
import Delete_Users from "../../components/delete-users/delete-users";
import Forget_Password from "../../components/forget-password/forget-password";
import Search_Bar from "../../components/search-bar/search-bar";
import gear from "../../images/gear.svg";

const Forgot_Password_Wrapper = (props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm({ searchTerm: e.target.value });
    console.log(searchTerm);
  };

  return (
    <div>
      <div className="form-format mt-3 mb-4">
        <Search_Bar
          handleChange={(e) => {
            setSearchTerm(e.target.value);
            console.log(searchTerm);
          }}
        />
      </div>
      {props.isLoaded ? (
        <img className="gear" src={gear} alt="" />
      ) : (
        <div className="wrapper-array">
          {console.log(props.userInfo.role)}

          {props.userInfo
            .filter((userInfo) => {
              if (userInfo.status == "Active") {
                if (searchTerm === "") {
                  return userInfo;
                } else if (
                  userInfo.username
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return userInfo;
                }
              }
            })

            .map((userInfo) => (
              <Forget_Password
                key={userInfo.id}
                photo={userInfo.photo}
                username={userInfo.username}
                designation={userInfo.designation}
                email={userInfo.email}
                coins={userInfo.coins}
                status={userInfo.status}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Forgot_Password_Wrapper;
