import React, { Component } from "react";
import "./notifications.css";
class Notifications extends React.Component {
  render() {
    return (
      <div className="notifications-wrapper">
        <h3>Leave Request</h3>
        <p className="notifications-para">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
          optio minima quaerat, ipsa amet aliquid maxime nihil, dolores
          laudantium earum commodi corporis
        </p>
      </div>
    );
  }
}

export default Notifications;
