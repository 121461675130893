import React, { Component } from "react";
import cross from "../../images/proceed.svg";
import "./dashboard-leavereq.css";

import profile from "../../images/profile2.jpg";
class Dash_Leavereq extends React.Component {
  render() {
    return (
      <div>
        <div className="dash-leave-wrapper shadow">
          <div className="dash-leave-requests">
            <div>
              <img className="dash-prof shadow-sm" src={profile} alt="" />
            </div>
            <div>
              <h2>User Name</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing</p>
            </div>
            <div className="dash-proceed shadow-sm">
              <img src={cross} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dash_Leavereq;
