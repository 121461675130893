import React, { Component, useState } from "react";
import profile from "../../images/profile-edit.jpg";
import eye from "../../images/eye.svg";
import eye_slash from "../../images/eye-slash.svg";
import randompas from "../../images/randompas.svg";
import { userSchema } from "../../Validation/add-office-valid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import curve from "../../images/curve.svg";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import Errors_Display from "./../errors-display/errors-display";
import Display_Supervisor from "../display-supervisor/display-supervisor";
import { useHistory } from "react-router-dom";

const Edit_Offices = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  const history = useHistory();
  const { id, image, name, email, address, phone } = props.location.state;

  const [image_file, setImageFile] = useState();
  const [form_error, setForm_error] = useState();
  const [role_check, setRole_check] = useState();
  const [disabled, setDisabled] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [randomPass, setRandomPass] = useState();
  // Math.random().toString(36).slice(-8)

  const splitError = () => {
    if (form_error) {
      const array = form_error.split(",");
      console.log(array);
      return array.map((data, i) => (
        <Errors_Display array={array[i]} index={i} />
      ));
    }
  };

  const generate_random = () => {
    var randomstring = Math.random().toString(36).slice(-8);
    setValue("password", randomstring);

    // setRandomPass(randomstring);
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const resetForm = () => {
    document.getElementById("addusers").reset();
  };

  //submitting API
  const HandleForm = (data) => {
    if (!disabled) {
      const loader = toast.loading("Loading");
      setDisabled(true);
      //resetting errors array
      setForm_error("");
      data["file"] = image_file;

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      //adding image

      // formData.append("file", image_file);

      //removing supervisedby if user is not an employee
      // if (role_check === "employee") {
      //   formData.append("supervisedBy", data.supervisedBy);
      // }
      // formData.append("officeId", "6125615f221423d73cfd01eb");

      // formData.append("name", data.name);
      // formData.append("password", data.password);s
      // formData.append("role", data.role);

      // formData.append("address", data.address);
      // formData.append("email", data.email);
      // formData.append("leaves", data.leaves);
      // formData.append("JoiningDate", data.JoiningDate);
      // formData.append("file", image_file);

      // data["officeId"] = "6125660247b6eaacecff03bc";
      // console.log(data);

      fetch(
        `${process.env.REACT_APP_BASEURL}/api/v1/offices/${props.location.state.id}`,
        {
          method: "PUT",

          headers: {
            // "Content-Type": "application/json",
            // "Content-Length": data.toString().length + "",
            // Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          setDisabled(false);
          if (responseJson.success) {
            toast.success(`Office status updated`, {
              id: loader,
            });

            resetForm();
          } else {
            setForm_error(responseJson.error);
            toast.error(`${responseJson.error}`, {
              id: loader,
            });
          }
          history.push("/superadmin/offices");
        })
        .catch((err) => {
          toast.error(`${err}`, {
            id: loader,
          });
        });
    }
  };

  // const HandleForm = (data) => {
  //   setForm_error("");
  //   data.JoiningDate = format(data.JoiningDate, "yyyy-MM-dd");
  //   let formData = new FormData();

  //   //removing supervisedby if user is not an employee
  //   if (role_check === "employee") {
  //     formData.append("supervisedBy", data.supervisedBy);
  //   }
  //   formData.append("name", data.name);
  //   formData.append("password", data.password);
  //   formData.append("role", data.role);
  //   formData.append("officeId", "6125615f221423d73cfd01eb");
  //   formData.append("address", data.address);
  //   formData.append("email", data.email);
  //   formData.append("leaves", data.leaves);
  //   formData.append("JoiningDate", data.JoiningDate);
  //   formData.append("file", image_file);

  //   data["officeId"] = "6125660247b6eaacecff03bc";
  //   // console.log(data);

  //   fetch(`${process.env.REACT_APP_BASEURL}/api/v1/users`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       // "Content-Type": "application/json",
  //       // "Content-Length": data.toString().length + "",
  //       // Accept: "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log(responseJson);
  //       if (responseJson.success) {
  //         toast.success(`User Added Successfully`);
  //       } else {
  //         setForm_error(responseJson.error);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("An error has occurred");
  //     });
  // };

  return (
    <div className="bigwrapper shadow">
      <div className="pt-5 second-wrapper">
        <form
          enctype="multipart/form-data"
          id="addusers"
          onSubmit={handleSubmit(HandleForm)}
        >
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              console.log(e.target.files[0]);
              setImageFile(e.target.files[0]);
            }}
            className="form-control file-upload"
            name="photo"
          ></input>
          {/* <input
            type="file"
            id="picture"
            name="picture"
            // {...register("picture")}
          ></input> */}

          <div className="seperator">
            <div>
              <label for="name">Office Name</label>
              <br></br>
              <input
                className="form-control  mt-2 form-rounded"
                type="text"
                placeholder={name}
                id="name"
                name="name"
                size="10"
                {...register("name")}
              ></input>
              <br></br>
              <label for="email">Email</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="text"
                id="email"
                placeholder={email}
                name="email"
                size="30"
                {...register("email")}
              ></input>
              <br></br>
            </div>
            <div>
              {/* <label for="pw">
                Password 
              </label>
              <br></br>
              <div className="eye-container">
                <img
                  className="randompas-add-users"
                  src={randompas}
                  onClick={() => generate_random()}
                  alt=""
                />
                <img
                  className="eye-add-users"
                  src={showPass ? eye_slash : eye}
                  onClick={() => {
                    setShowPass((prevCheck) => !prevCheck);
                  }}
                  alt=""
                />
                <input
                  className="form-control password-field mt-2 form-rounded"
                  autocomplete="new-password"
                  type={showPass ? "text" : "password"}
                  value={randomPass}
                  onChange={(e) => setRandomPass(e.target.value)}
                  // onInput={(e) => setRandomPass(e.target.value)}
                  id="password"
                  name="password"
                  size="20"
                  {...register("password")}
                />
              </div> */}

              <label for="phone">Phone Number</label>
              <input
                name="phone"
                className="form-control mt-2 form-rounded"
                min="0"
                placeholder={phone}
                type="number"
                id="phone"
                {...register("phone")}
              ></input>
              <br></br>
              <label for="address">Address</label>
              <br></br>
              <input
                className="form-control mt-2 form-rounded"
                type="text"
                id="address"
                placeholder={address}
                name="address"
                size="30"
                {...register("address")}
              ></input>
              <br></br>
            </div>
          </div>
          <div className="submit-form pb-4">
            <input
              className=" me-3 shadow submit-btn submit-btngrey"
              type="reset"
              value="Discard"
            ></input>
            <input
              className=" submit-btn shadow submit-btnblue "
              type="submit"
              value="Submit"
            ></input>
          </div>
        </form>
        <div className="">
          <h3 className={errors.email?.message ? "red" : "hide"}>
            {errors.email?.message}
          </h3>

          <h3 className={errors.password?.message ? "red" : "hide"}>
            {" "}
            {errors.password?.message}
          </h3>

          <h3 className={errors.cnic?.message ? "red" : "hide"}>
            {" "}
            {errors.cnic?.message}
          </h3>

          <h3 className={errors.phone_no?.message ? "red" : "hide"}>
            {errors.phone_no?.message}
          </h3>
          <h3
            className={errors.password_confirmation?.message ? "red" : "hide"}
          >
            {errors.password_confirmation?.message}
          </h3>
          {form_error ? splitError(form_error) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Edit_Offices);
