import React, { Component } from "react";
import "./requests.css";
class Requests extends Component {
  state = {};
  render() {
    return (
      <div className="requests-wrapper shadow">
        <div className="dishname">
          <h2>Dish Name</h2>
          <h3>Requested by Sheoel Shakil</h3>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            nemo culpa quibusdam quasi inventore labore officiis
          </p>
        </div>
        <div className="requests-buttons">
          <a className="requests-blue" href="">
            Add Dish
          </a>
          <a className="requests-red" href="">
            Remove
          </a>
        </div>
      </div>
    );
  }
}

export default Requests;
