import React, { Component } from "react";
import "./toggle.css";
const Toggle = () => {
  return (
    <label className="switch shadow">
      <input type="checkbox" />
      <span className="slider" />
    </label>
  );
};

export default Toggle;
