import * as yup from "yup";
import { format } from "date-fns";
const date = new Date();
export const userSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("Please enter a valid email address"),
  password: yup.string().min(6, "Password cannot be shorter than 6 characters"),
  address: yup.string(),
  phone: yup.string(),
  photo: yup.string(),
});
