import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./psreq-sent.css";
import email from "../../images/email.svg";
class Psreq_Sent extends React.Component {
  render() {
    return (
      <div className="sign-in-background-ps">
        <div className="sign-in-wrapper-ps">
          <div>
            <img src={email} alt="" />
          </div>
          <div className="ps-text">
            <h2>Email Sent</h2>
            <h3>
              Instructions to reset your password
              <br /> has been sent to your email address
            </h3>
          </div>

          <div>
            <Link to={"/"} className="sign-inbtn-ps mb-2" href="">
              Back
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Psreq_Sent;
