import React, { Component } from "react";
import profile from "../../images/profile-edit.jpg";
import "./edit-dish.css";
class Edit_Dish extends Component {
  state = {};
  render() {
    return (
      <div className="bigwrapper shadow">
        <div className="pt-5 second-wrapper">
          <img className="shadow" src={profile} alt="" />
          <form>
            <div className="seperator">
              <div>
                <label for="dname">Dish Name</label>
                <br></br>
                <input
                  className="form-control  mt-2 form-rounded-editdish"
                  type="text"
                  id="dname"
                  name="dname"
                  size="10"
                ></input>
                <br></br>
                <label for="lname">Description</label>
                <br></br>
                <textarea
                  className="form-control mt-2 form-rounded-editdish"
                  type="text"
                  id="desc"
                  name="desc"
                  size="30"
                  rows="4"
                ></textarea>
                <br></br>
              </div>
            </div>
            <div className="submit-form pb-4">
              <input
                className=" me-3 submit-btn editdish-btn-discard"
                type="reset"
                value="Discard"
              ></input>
              <input
                className=" submit-btn editdish-btn-save"
                type="submit"
                value="Save"
              ></input>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Edit_Dish;
