import React, { Component } from "react";
import cross from "../../images/proceed.svg";
import "./forget-password.css";
import profile from "../../images/profile2.jpg";

const Forget_Password = (props) => {
  return (
    <div className="forgot-wrapper shadow">
      <div className="forgot-requests">
        <div>
          <img className="prof shadow-sm" src={profile} alt="" />
        </div>
        <div>
          <h2>{props.username}</h2>

          <p className="mt-3">Email: {props.email}</p>
          <p>Digicoins: {props.coins}</p>
        </div>
        <div className="proceed shadow-sm">
          <img src={cross} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Forget_Password;
